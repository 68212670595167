#contact-section{
    min-height: calc(100vh - 5rem);
    width: 100%;
    padding: 2rem 8rem 13rem 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -5rem auto;

}

#contact-title{
    font-size: 6rem;
    font-weight: 600;
    color: var(--section-title-color);
}

#contact-description {
    font-size: 1.2rem;
    font-weight: 200;
}

#contact-text-container{
    display: flex;
    flex-direction: column;
    width: 65%;
}

#name, #email, #message {
    font-size: 1rem;
    width: 100%;
    max-width: 100%;
    min-height: 3rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(47, 46, 51);
    &::placeholder{
        color: rgb(206, 206, 206);
        font-family: var(--font-family);
    }
}

#contact-form{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
}

.submit-btn{
    background: white;
    margin: 2rem 0;
    border: none;
    border-radius: 2rem;
    width: 30%;
    height:auto;
    font-weight: 600;
    text-align: center;
    padding: 1rem 1rem;
    cursor: pointer;
    transition: transform 0.2s;
    font-size: 2rem;
    color: black;

    &:hover{
        transform: scale(1.1);
        text-shadow:1px 2px 0px var(--secondary-highlight-color);
        color: var(--highlight-color); 
    }
}
   

@media screen and (max-width:720px){
    #contact-text-container{
        width: 100%;
    }
    .submit-btn{
        width: 80%;
        padding: 15px;
    }
    #contact-section{
        padding: 5rem 2rem;
        text-align: center;
    }
    #contact-form{
        width: 80%;
    }
}

@media screen and (max-width:480px){
    #contact-title{
        font-size: 4rem;
    }
}
