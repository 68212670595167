
.navbar{
    height: 5rem;
    width: 100vw;
    margin: 0;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    font-size: 1.5rem;
    background-color: var(--bg-color);
}

.logo{
    object-fit: cover;
    height: 5rem;
    width: auto;
}

.desktop-menu-list-item{
    margin: 1rem;
    cursor: pointer;
    color: white;

    &:hover{
        color: var(--highlight-color);
        padding-bottom: 0.5rem;
        border-bottom: 3px solid var(--highlight-color);
    }
}

.desktop-menu-btn{
    border: none;
    background-color: white;
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    transition: transform 0.2s;

    &:hover{
        transform: scale(1.05);
    }
}

.desktop-menu-email-icon{
    object-fit: cover;
    height: 1.2rem;
    width: auto;
    margin: 1rem 1rem 1rem 0;
}

.burger-menu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: var(--bg-color);
    border-radius: 1rem;
    text-align: center;
}

.mobMenu {
    cursor: pointer;
    display: none;
}
  
.menu-bar {
    width: 30px;
    height: 4px;
    background-color: var(--text-color);
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 2rem;
  }
  
.open {
    transform: rotate(-45deg);
    transition: 0.2s; 
}

.open:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: 0% 0%;
}
  
.open:nth-child(2) {
    opacity: 0;
}
  
.open:last-child {
    transform: rotate(-45deg);
    transform-origin: 00% 100%;
}

.burger-menu-list-item{
    cursor: pointer;
    color: white;
    padding: 1rem;
    margin: 0.25rem;
    border-radius: 0.5rem;
    transition: background 0.1s;

    &:hover{
        color: var(--highlight-color);
        background: var(--secondary-bg-color);
    }
}

.active{
    color: var(--highlight-color);
}
  

/* Tablets (portrait) ----------- */
@media screen and (max-width: 720px){
    .desktop-menu-btn{
        display: none;
    }
    .desktop-menu-list-item{
        display: none;
    }

    .mobMenu{
        display:inline-block;
    }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 480px) {
   
    .logo{
        height: 3rem;
        width: auto;
    }

}