#skills-section{
    width: 100%;
    margin: 0 auto;
    padding: 5rem 8rem 13rem 8rem;
    line-height: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#section-text-container{
    display: flex;
    flex-direction: column;
    width: 65%;
}

#skill-card-img{
    height: 15rem;
    width: auto;
    object-fit: cover;
}

.skill-card-container{
    max-width: 65vw;
    margin: 1rem auto 0 auto;
    text-align: center;
    max-width: 65%;
}

.skill-title{
    font-size: 6rem;
    font-weight: 600;
    color: var(--section-title-color);
    margin-bottom: 2rem;
}

.skill-description{
    font-size: 1.2rem;
    font-weight: 200;
}

.skill-card{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 2rem;
    padding: 1rem 2rem;
}

.skill-card-img{
    margin-right: 4vw;
    height: 10vw;
}

.skill-card-text h2 {
    margin-bottom: 3vh;
    font-size: 2.7rem;
    line-height:.9;
}

.skill-card-text p {
    font-size: 1.1rem;
    font-weight: 300;
}

@media screen and (max-width:1325px) {
    #section-text-container{
        width: 80%;
    }

    .skill-card-container{
        max-width: 80%;
    }

    .skill-card{
        padding: 2rem;
        margin: 2rem auto;
    }


    .skill-card-text h2{
        font-size: 2.1rem;
    }
}

@media screen and (max-width:720px){
    .skill-card-img{
        padding: 0;
        margin: 0;
        height: auto;
        width: 100%;
    }
    #full-stack-icon{
        width: 100%;
        height: auto;

    }
    .skill-card-text h2 {
        margin-top: 1rem;
        margin-bottom: 0.8rem;
        font-size: 1.8rem;
    }
    .skill-card-text p{
        font-size: 0.9rem;
        line-height: 1.2;
    }
    
    .skill-card{
        flex-direction: column;
        padding: 2rem;
    }
    .skill-title{
        padding-bottom: 0.25rem;
    }
    
    #skills-section{
        padding: 5rem 3rem;
        width: 100%;
        text-align: center;
    }
}


@media screen and (max-width : 581px) {
    .skill-card{
        width: 100%;
    }
    #skills-section{
        padding: 0;
    }
    .skill-title{
        font-size: 4rem;
    }
}