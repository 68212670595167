:root {
    --bg-color: rgb(5, 0, 39);
    --secondary-bg-color: rgb(69, 64, 97);
    --text-color: white;
    --highlight-color: #c46cdf;
    --secion-title-color: white;
    --font-family: 'Poppins', sans-serif;
    --secondary-highlight-color: #c4fa59;
}

.App {
    margin: 0;
    padding: 0;
    width: 100%;
    text-decoration: none;
    background: transparent;
    font-family: var(--font-family);
    line-height: 1;
    box-sizing: border-box;
}


#page-body{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}