#portfolio-section{
    min-height: calc(100vh - 5rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem 8rem 13rem 8rem;
}

#portfolio-section-text{
    display: flex;
    flex-direction: column;
    width: 65%;
}

#portfolio-title{
    font-size: 6rem;
    font-weight: 600;
    color: var(--section-title-color);
}

#portfolio-description{
    font-size: 1.2rem;
    font-weight: 200;
    max-width: 100%;
    line-height: 1.2;
}

.portfolio-info{
    padding: 0 1rem;
    text-align: center;
    margin: 1rem 0 0.5rem 0;
}

.portfolio-info a{
    text-align: right;
    color: white;
    font-size: 1.5rem;
    border: 1px solid var(--highlight-color);
    padding: 0 1rem;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        color: var(--highlight-color);
    }
}

#portfolio-imgs{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 65%;
    padding-top: 1rem;
}

.portfolio-img{
    border-radius: 15px;
    overflow: hidden;
    height: 15rem;
    width: 15rem;
    margin: 0.5rem;
    transition: transform 0.2s;
    flex-basis: calc(33.33% - 1rem); /* Set each image container to 33.33% width with 1rem spacing between them */


    &:hover{
       transform: scale(1.05); 
    }
}

.portfolio-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:fit-content;
}


#portfolio-btn{
    padding: 0.5rem 2.5rem;
    background-color: white;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    margin: 3rem 0 0 0;
    font-weight: 600;
    height: 6vh;
    width: 15rem;
}

#lovins-code{
    visibility: hidden;
}

@media screen and (max-width:1325px) {
    .portfolio-img{
        transition: none;
        &:hover{
            transform: none;
        }

    }
    
    #portfolio-section{
        width: 100%;
        padding-bottom: 15rem;
    }

    #portfolio-section-text{
        width: 80%;
    }

    #portfolio-imgs{
        margin: 0;
        width: 80%;
    }
}


@media screen and (max-width: 720px) {
    .portfolio-img {
        margin: 0;
        width: 100%;
        height: auto;
    }

    #lovins-img{
        margin-top: -1rem;
    }
    #portfolio-imgs{
        gap: 2rem;
    }

    .portfolio-item{
        width: 100%;
    }

    #portfolio-description{
        padding-right: 0;
    }

    #portfolio-section{
        padding: 5rem 2rem;
        text-align: center;
        margin: 0;
        width: 100%;
    }

    .portfolio-info{
        margin-bottom: 1rem;
    }
    
}

@media screen and (max-width: 480px) {
    #portfolio-title{
        font-size: 4rem;
    }

    #portfolio-description{
        padding: 0;
    }

    #portfolio-btn{
        width: 50vw;
    }

    #portfolio-section-text{
        width: 100%;
    }
}

